.cols {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.percent {
  display: flex;
  padding-top: 20px;
  align-items: center;
}

.col {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 20px;
  transition: box-shadow 0.3s, filter 0.3s;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.selectedCard {
  background: #038819 !important;
  color: #ffff !important;
  filter: brightness(1.2);
  box-shadow: 0 4px 8px rgba(76, 217, 100, 0.3);
}

.cardContainer{
  display: flex;
  justify-content: space-between;

}

.variants {
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.var1 {
  font-size: 20px;
  font-weight: 700;
  color: #038819;
}

.cardtit {
  text-transform: uppercase;
  font-weight: 600;
  line-height: 1.5;
  color: #038819 !important;
  font-size: 20px;
}

.salespr {
  font-weight: 600 !important;
  font-size: 1.25rem;
  line-height: 1.5;
  color: #32325d;
}

.ratiopercent {
  /* color: #2dce89 !important; */
  /* font-size: 0.875rem !important; */
  font-weight: 300;
  line-height: 1.7;
  color: red;
  font-size: 15px;
}

.dater {
  padding-left: 20px;
}

.ranges {
  white-space: nowrap !important;
  font-weight: 300;
  line-height: 1.7;
  color: #ffff !important;
  font-size: 14px !important;
}

.chart {
  height: auto !important;
  padding: 20px !important;
  width: 100% !important;
}
.chart2 {
  height: 327px !important;
  padding: 20px !important;
  width: 100% !important;
}

/* .chartdataa{
  width: 1350px !important;
} */
.graphsdataa {
  padding-top: 50px;
}

.shadow {
  border-radius: 12px 12px 12px 12px !important;
  background-color: #ffffff !important;
  box-shadow: 4px 4px 8px 1px #d6d5d2;
}

.ratesicons {
  display: flex;
  align-items: center;
}

.dash {
  color: #000;
}

.card {
  width: 200px;
  height: 100%;
  flex-shrink: 0;

}

.selectedCardtit {
  text-transform: uppercase;
  font-weight: 600;
  line-height: 1.5;
  color: #ffff !important;
  font-size: 20px;
}

.iconscolor {
  fill: #ffff !important;
}

.losstext {
  color: red !important;
}

.inventorycard {
  padding-bottom: 50px;
  cursor: not-allowed !important;
  pointer-events: none !important;
}

.fullWidthCard {
  width: 100%;
}
.dataicons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profittext {
  color: #038819 !important;
}

.profitcolr {
  color: #ffff !important;
}

.coloricons {
  color: #ffff !important;
}

.pricecolors {
  color: #ffff !important;
}

.smallAmounts{
  font-size: 16px;
  font-weight: bold;
}


@media (min-width: 100px) and (max-width: 400px) {
  html{
    /*overflow-x: hidden;*/

  }
  .filterContainer {
    max-width: 100% !important;
    display: flex !important;
    flex-direction: column !important;

  }

  .cardContainer {
    width: 100%;
    /* display: flex !important;
    flex-direction: row !important;
    align-items: center;
    flex-wrap: nowrap !important; */
    gap: 20px;
    flex-direction: column;
    align-items: center;

  }

  .plants{
    max-width: 100% !important;
  }

  .boxContainer{
    width: 100%;
    overflow: scroll ;
  }

  .card {
    width: 100%;
    height: 100%;
    flex-shrink: 0;

  }
  .dateFilters{
    max-width: 100% !important;
    justify-content: unset;
  
  }


}

@media (min-width: 320px) and (max-width: 700px) {
  html{
    /* overflow-x: hidden; */
  }

  .graphContainer{
    flex-direction: column !important;
    
  }

  .graph{
    max-width: 100% !important;
  }

  .filterContainer {
    width: 100%;
    display: flex !important;
    flex-direction: column !important;

  }

  .cardContainer {
    width: 100%;
    /* display: flex !important;
    flex-direction: row !important;
    align-items: center;
    flex-wrap: nowrap !important; */
    gap: 20px;
    flex-direction: column;
    align-items: center;


  }

  .plants{
    width: 100% !important;
  }

  .boxContainer{
    width: 100%;
    /* overflow: scroll ; */
  }

  .card {
    width: 100%;
    height: 100%;
    flex-shrink: 0;

  }

  .dateFilters{
    max-width: 100% !important;
    justify-content: unset;

  }

}

@media (min-width: 700px) and (max-width: 1100px) {
  html{
    overflow-x: hidden;
  }
  .filterContainer {
    width: 100%;
    display: flex !important;
    flex-direction: column !important;
    gap: 20px;
    justify-content: space-between;

  }

  .cardContainer {
    width: calc(100% + 416px) !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center;
    flex-wrap: nowrap !important;
    gap: 20px;
  }

  .plants{
    width: 100% !important;
  }

  .boxContainer{
    width: 100%;
    overflow: scroll ;
  }

  .card {
    width: 200px;
    height: 100%;
    flex-shrink: 0;

  }

  .dateFilters{
    max-width: 100% !important;
    justify-content: unset;

  }



}

@media (min-width: 1100px) and (max-width: 1200px) {
  html{
    overflow-x: hidden;
  }
  .filterContainer {
    width: 100%;
    display: flex !important;
    flex-direction: row !important;

  }

  .cardContainer {
    width: calc(100% + 344px) !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center;
    flex-wrap: nowrap !important;
    gap: 20px;

  }

  .plants{
    width: 100% !important;
  }

  .boxContainer{
    width: 100%;
    overflow: scroll ;
  }

  .card {
    width: 150px;
    height: 100%;
    flex-shrink: 0;

  }
  .dateFilters{
    max-width: 100% !important;
    justify-content: unset;

  }


}
