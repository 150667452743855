.wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    padding-left: 20px;
  }
  .searchContainer {
    position: relative;
    margin: 0 10px;
  }
  .searchInput {
    width: 250px;
    background: #edeceb;
    border: none;
    outline: none;
    padding: 0.8rem;
    border-radius: 4px;
  }
  .searchInput:focus {
    outline: none;
    border: none;
  }
  .searchIcon {
    position: absolute;
    right: 0.5rem;
    top: 20%;
    cursor: pointer;
  }