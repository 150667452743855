.input {
  height: 20%;
  width: 10%;
  margin: 20px;
}

.label {
  color: #302c2c;
  text-align: left;
  font-size: 20px;
}

@media only screen and (min-width: 320px) and (max-device-width: 425px) {
  .label {
    color: #302c2c;
    text-align: left;
    font-size: 16px;
  }
}

.inputbox {
  border: 0;
  border-bottom: 1px solid #302c2c;
  width: 100%;
  padding: 0 2px 6px 0;
}

.asterick {
  color: #ff2400;
  padding: 5px;
}

.inputerror {
  border-bottom: 1px solid #ff2400;
}

.errortext {
  color: #ff2400;
}

.inputDiv {
  width: 100%;
  margin: 5px 0 0 0;
  display: flex;
  flex-direction: row-reverse;
}

.inputDiv input {
  font-size: 16px;
  padding: 5px 10px;
}

.passwordIcon {
  position: absolute;
}

.inputDisabled {
  background: #edeceb;
}
