.header {
  box-shadow: 4px 4px 8px 1px #d6d5d2;
  width: 100%;
}

.headercontent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.logout {
  margin: auto 0;
  padding-right: 10px;
  cursor: pointer;
  text-align: center;
}

.logo {
  display: flex;
  align-items: center;
}

.headertitle {
  font-size: 35px;
  color: #038819 ;
}

.logoclick {
  cursor: pointer;
}

.logoHeader {
  height: 100px;
}

.userId {
  white-space: nowrap;
}
@media screen and (min-device-width: 900px) and (max-device-width: 1400px) and (-webkit-min-device-pixel-ratio: 1) {
  .headertitle {
    font-size: 28px;
    color: #038819 ;
  }
  .logoHeader {
    height: 80px;
  }
}

@media only screen and (max-width: 600px) {
  .headertitle {
    font-size: 18px;
    color: #038819 ;
  }
  .logoHeader {
    height: 60px;
  }
}

@media only screen and (min-width: 320px) and (max-device-width: 425px) {
  .headertitle {
    font-size: 18px;
    color: #038819 ;
  }
  .logoHeader {
    height: 50px;
    margin-top: 10px;
  }
}
