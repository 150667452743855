.backButton {
  color: #038819 ;
  font-size: 25px;
  padding: 10px 10px 10px 0px;
  margin: 0 20px;
  margin-top: 5px;
}

.backButton:hover {
  cursor: pointer;
}

@media screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1400px) 
  and (-webkit-min-device-pixel-ratio: 1) { 
    .backButton{
        font-size: 18px;
    }
}
