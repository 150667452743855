  @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
body::-webkit-scrollbar{ 
  width: 10px;
}
.poppins{
  font-family: 'Poppins', sans-serif;
}
body::-webkit-scrollbar-thumb {
  background-color: #888; 
  border-radius: 10px;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #555; 
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#root {
  width: 100%;
  flex: 1;
  display: flex;
  height: 100%;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
}
@media screen and (min-device-width: 1024px) and (max-device-width: 1400px) and (-webkit-min-device-pixel-ratio: 1) {
  .App {
    font-size: 14px;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input:focus {
  outline: none;
  border-bottom: 1px solid #302c2c;
}

/* remove arrows for number input */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.react-date-picker__wrapper {
  border: none;
}

.mantine-Textarea-input:focus {
  outline: none;
  border-color: #bdf2ca;
}
.containermax{
  /* background-color: #ffff; */
     background: #fffefe;
     
  }
.grid7> div  >span > p{
  text-align: center;
  color: red;
  font-size: 1.5rem;
  font-family: 'Poppins', sans-serif;
}
.datefilters > div:nth-child(1){
 margin-top: 10px;
}
.datefilters >div > div > div > div > div > div > input {
  padding: 17px;
  background-color: #fff !important;
  width: 150px;
}
.plants > div > div >label{
  color: #000 !important;
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
    line-height: 6px;
}
.datefilters > div > div > div > span{
  color: #000 !important;
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
    line-height: 6px;
}
.datefilters{
  display: flex;
  justify-content: center;
}
.datefilters > div >div >div > div > div > div {
  margin-top: 5px;
}
.backbtn{
  color: #038819;
  font-size: 25px;
  /* padding: 10px 10px 10px 0px; */
  /* margin: 0 20px; */
  /* margin-top: 5px; */
}
/* .admindash > div > div {
  margin: 0px !important;
} */

@media (min-width: 100px) and (max-width: 700px) {
.containermax{
  width: max-content;
  max-width: unset;
}
  
}