.card {
  height: fit-content;
  width: fit-content;
  border: 1px solid #5a5a5a;
  border-radius: 5px;
  background: #ffffff;
}

.text {
  text-align: center;
  padding: 30px;
}

.maintext {
  font-size: 25px;
  color: #302c2c;
}

.subtext {
  padding-top: 10px;
  font-size: 18px;
  color: #302c2c;
}

.button {
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;
}

.buttonwidth {
  /* width: 100px; */
}

@media screen and (min-device-width: 1024px) and (max-device-width: 1400px) and (-webkit-min-device-pixel-ratio: 1) {
  .maintext {
    font-size: 18px;
  }
  .subtext {
    font-size: 14px;
  }
}
