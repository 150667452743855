.outerWrapper {
  margin: 10px;
  max-width: 800px;
  padding: 10px;
  margin-top: 0;
  padding-top: 0;
}
.innerWrapper {
  border: 1px solid #e2e2e2;
  padding: 1rem;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.header {
  color: #038819 ;
  font-size: 20px;
  font-weight: 500;
  margin-top: 3rem;
  line-height: 1rem;
}
.formButton {
  width: fit-content;
}
@media screen and (min-device-width: 1024px) and (max-device-width: 1400px) and (-webkit-min-device-pixel-ratio: 1) {
    .header {
        font-size: 16px;
    }
}
