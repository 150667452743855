.logincard {
  width: 100%;
  max-width: 30rem;
  box-shadow: 3px 1px 7px 1px #d6d5d2;
  margin: auto auto;
  padding: 20px 0 0 0;
}

.loginlogo {
  margin: auto;
  text-align: center;
  padding: 10px;
}
.loginlogo img {
  max-width: 380px;
}

.logotitle {
  text-align: center;
  font-size: 30px;
  color: #302c2c;
}

.inputlabel {
  margin: 50px 50px 80px 50px;
}

.label {
  padding-top: 30px;
}

.loginbtn {
  margin: 50px 0 10px 0;
}

.loginPage {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
}

@media only screen and (max-width: 600px) {
  .logotitle {
    font-size: 22px;
  }
  .loginlogo img {
    max-width: 220px;
  }
  .inputlabel {
    margin: 50px 20px 80px 20px;
  }
}
