.innerWrapper {
  display: flex;
  gap: 4rem;
}


.dateInput {
  background: #fff;
}
.inputTitle {
  line-height: 4px;
  color: #302c2c;
}

.buttonWrapper {
  height: fit-content;
  align-self: flex-end;
  display: flex;
}

.btnSubWrapper {
  gap: 5px;
}

@media only screen and (max-width: 600px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background: #f3f3f3;
    padding: 0.5rem 1.5rem 1.5rem 1.5rem;
    border-radius: 8px;
  }
  .buttonWrapper {
    height: fit-content;
    align-self: flex-start;
  }
}


@media (min-width: 100px) and (max-width: 400px) {
  .startDate{
    width: 50%;
  }
  .endDate{
    width: 50%;
  }
  
  .innerWrapper{
    display: flex;
    flex-direction: column;
  }
}

