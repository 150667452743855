.textareastyle{
    width: 100%;
    border-color: #302C2C;
    margin-top: 5px;
    padding: 5px;
    font-size: 16px;
}

.textareatitle{
    color: #302C2C;
    font-size: 20px;
    text-align: left;
}

@media screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1400px) 
  and (-webkit-min-device-pixel-ratio: 1) { 
    .textareastyle{
        font-size: 12px;
    }
    .textareatitle{
        font-size: 16px;
    }
}