.header {
  color: #038819 ;
  font-size: 20px;
  font-weight: 500;
  line-height: 1rem;
}

.headerWrapper {
  display: flex;
  align-items: center;
  width: 80%;
  justify-content: space-between;
}

.purchaseListHeader {
  text-align: center;
  color: #038819 ;
  font-size: 20px;
  font-weight: 500;
  line-height: 1rem;
  margin-top: -1.5rem;
}

.billWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 3px solid #edeceb;
}

.billGenerator {
  border-right: 3px solid #edeceb;
  width: 60%;
}

.billGenerator > div {
  /* overflow: hidden; */
  /* padding: 0px 10px; */
}

.itemTitleWrap {
  display: flex;
  padding: 0 10px;
  justify-content: space-between;
  align-items: center;
}

.formWrapper {
  display: flex;
  justify-content: flex-start;
  /* flex-wrap: wrap; */
  max-width: 100%;
  gap: 5px;
  padding: 0 10px;
  /* align-items: flex-end; */
}

.formWrapper > div {
  margin: 0px 40px 0px 0px;
}

.billHistory {
  width: 40%;

}

.iconButton {
  background-color: #038819 ;
  width: 28px;
  height: 28px;
  border-radius: 14px;
  border: 0px;
  color: #ffffff;
  cursor: pointer;
}

.iconButton:disabled {
  background: #b1a8a8;
  cursor: not-allowed;
}

.cartTableContainer {
  max-width: 90vw;
  overflow: auto;
  padding: 10px 0;
  margin: 0 10px;
  padding: 10px 0 10px 0;
  padding-bottom: 10rem;
  height:10vh;
  overflow: auto;
}

.cartTableContainer::-webkit-scrollbar {
  width: 5px; 
}

.cartTableContainer::-webkit-scrollbar-thumb {
  background-color: #888; 
  border-radius: 10px;
}

.cartTableContainer::-webkit-scrollbar-thumb:hover {
  background-color: #555; 
}

.cartTable {
  width: 100%;
}

.cartTable th {
  color: #038819 ;
}

.cartTable td {
  text-align: center;
}

.finalSubmission {
  width: 70%;
  display: block;
}

.finalSubmissionMobile {
  display: none;
}

.billDetails {
  margin: 23px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.historyTabContainer {
  display : flex;
  justify-content: space-around;
  align-items: center;
}

.singleTab {
  width : 50%;
  display : flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.billFigure {
  display: flex;
}

.billFigure > div {
  color: #038819 ;
  margin: 0px 10px;
}

.billFigure > span {
  color: #302c2c;
  font-weight: 500;
}

.submitWrapper {
  display: flex;
  justify-content: space-between;
  margin: 40px 0px;
}

.submitBtnWrapper {
  display: flex;
}

.submitBtnWrapper > button {
  margin: 0px 10px;
  width: auto;
  padding: 7px 20px;
}

.submitBtnWrapper > button > span {
  font-weight: 600;
  font-size: 16px;
}

.checkOutWrapper {
  display: flex;
  z-index: -1;
  justify-content: space-between;
  padding: 10px 0px 20px 0;
  border: none;
  margin: 0 10px 0 0
}

.checkOutWrapper > button {
  width: fit-content;
  padding: 7px 20px;
}

.checkOutWrapper > button > span {
  font-weight: 600;
  font-size: 16px;
}

.cartInput {
  border: 1px solid #dbd8d4;
  width: 50%;
  padding: 4px;
  border-radius: 4px;
}

.error {
  padding: 6px;
  border-radius: 4px;
  color: #ff2400;
}

.success {
  background-color: rgb(11, 196, 137);
  padding: 6px;
  border-radius: 4px;
  color: #ffffff;
}

.tableScroll {
  overflow: auto;
  height: 100px;
}

.selectDropDown {
  padding: 4px;
  border-radius: 4px;
}

.modalContent {
  min-width: 780px;
  padding: 48px;
}

.clientDetailsWrapper {
  display: flex;
  justify-content: space-between;
}

.headerModal {
  color: #038819 ;
  font-size: 20px;
  font-weight: 500;
  margin-top: 1rem;
  line-height: 1rem;
}

.headerWrapperModal {
  display: flex;
  justify-content: center;
}

.companyDetails {
  line-height: 24px;
}

.addressDetails {
  padding: 10px 0px 16px 0px;
}

.billedTo {
  padding: 26px 0px 16px 0px;
}

.lableValueDetails {
  display: flex;
}

.lableValueDetails .label {
  font-weight: 500;
}

.lableValueDetails .value {
  margin: 0px 10px;
}

.dicountDetails {
  display: flex;
}

.dicountDetails .lableValueDetails {
  display: flex;
  flex-direction: column;
}

.lableValueDetails .discountLabel {
}

.lableValueDetails .discountValue {
  font-weight: 500;
  margin: 0px 10px;
}

.invoiceSummary .lableValueDetails {
  line-height: 24px;
}

.clientDetails {
  line-height: 24px;
}

.invoiceSummary {
  display: flex;
  justify-content: space-between;
}

.thankYouNote {
  display: flex;
  justify-content: center;
  line-height: 24px;
  color: #ff2400;
}
.credits {
  display: flex;
  justify-content: center;
  line-height: 24px;
  color: #000000;
}

.modalAction {
  display: flex;
  justify-content: flex-end;
}

.modalAction button {
  width: 15%;
  margin: 0px 10px;
  padding: 7px 20px;
}

.modalAction > button > span {
  font-weight: 600;
  font-size: 16px;
}

.printButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.printButton button {
  width: auto;
  margin: 0px 10px;
  padding: 7px 20px;
}

.printButton > button > span {
  font-weight: 600;
  font-size: 16px;
}

@media print {
  .modalContent {
    padding: 48px;

    margin: 20px 0px;
  }
}

@media only screen and (max-width: 900px) {
  .billWrapper {
    flex-direction: column;
  }

  .billGenerator {
    border-right: 3px solid #edeceb;
    width: 100%;
  }

  .billHistory {
    width: 100%;
  }

  .checkOutWrapper {
    border-bottom: 1px solid #e2e2e2;
  }

  .finalSubmission {
    display: none;
  }

  .finalSubmissionMobile {
    width: 100%;
    overflow: hidden;
    max-width: 95vw;
    display: block;
  }

  .cartTable {
    width: max-content;
  }

  .billDetails {
    flex-direction: row;
  }
}

@media only screen and (max-width: 900px) {
  .billDetails {
    flex-direction: column;
    gap: 10px;
  }
}

@media screen and (min-device-width: 1024px) and (max-device-width: 1400px) and (-webkit-min-device-pixel-ratio: 1) {
  .header .headerModal {
    font-size: 16px;
  }

  .submitBtnWrapper > button > span {
    font-size: 14px;
  }

  .checkOutWrapper > button > span {
    font-weight: 600;
    font-size: 13px;
  }

  .modalAction > button > span {
    font-size: 13px;
  }
  .printButton > button > span {
    font-size: 13px;
  }
}
