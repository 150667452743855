.card {
  /* height: fit-content; */
  height: 100%;
  width: fit-content;
  border: 1px solid #5a5a5a;
  border-radius: 5px;
  background: #ffffff;
  overflow-y: scroll;
}
.card::-webkit-scrollbar {
  width: 5px;
}

.card::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #ffffff;
  border-radius: 5px;
}

.card::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px #C8C8CA;
}

.text {
  text-align: center;
  padding: 30px;
}

.maintext {
  font-size: 25px;
  color: #302c2c;
}

.subtext {
  padding-top: 10px;
  font-size: 18px;
  color: #302c2c;
}

.button {
  display: flex;
  justify-content: space-around;
  max-width: 30rem;
  margin: auto;
  margin-top: 4rem;
}

.buttonwidth {
  /* width: 100px; */
}

@media screen and (min-device-width: 1024px) and (max-device-width: 1400px) and (-webkit-min-device-pixel-ratio: 1) {
  .maintext {
    font-size: 18px;
  }
  .subtext {
    font-size: 14px;
  }
}
