.table {
  width: 100%;
  margin: 10px auto;
  border-collapse: separate;
  border-spacing: 0 1em;
  padding: 10px;
}

.tableHeader {
  color: #038819 ;
  font-weight: 600;
  border: 0 !important;
  padding: 5px;

  text-align: center;
}

.cellCommon {
  display: flex;
  justify-content: space-around;
}

.tableDataCommon {
  box-shadow: 4px 4px 8px 1px #d6d5d2;
  height: 80px;
  text-align: center;
}

.tableColorWhite {
  background-color: #ffffff !important;
}

.tableCommon {
  background-color: #edeceb;
  color: #302c2c;
}

.sort {
  cursor: pointer;
  padding-left: 5px;
  width: 18px;
}

.scrollTable {
  max-height: 426px;
  overflow: auto;
}

.scrollTable::-webkit-scrollbar {
  width: 5px; 
}

.scrollTable::-webkit-scrollbar-thumb {
  background-color: #888; 
  border-radius: 10px;
}

.scrollTable::-webkit-scrollbar-thumb:hover {
  background-color: #555; 
}



.noScrollTable{
    height: auto;
    max-height: unset !important;
    overflow: visible;
}
.table > thead > tr > th {
    position: sticky;
    top: 0;
    background-color: white;
  }

.noItemToDisplay {
  height: 426px;
  display: flex;
  justify-content: center;
  color: #038819 ;
  font-size: 16px;
  font-weight: 500;
  align-items: center;
}

@media print {
  .printableTable {
    background-color: #ffffff !important;
    -webkit-print-color-adjust: exact;
  }

  .scrollTableWrapper {
    page-break-before: auto;
    -webkit-print-color-adjust: exact;
    display: block !important;
    position: relative !important;
    width: auto !important;
    height: auto !important;
    overflow: visible !important;
  }
}
