.footer {
  box-shadow: 4px 4px 6px 4px #d6d5d2;
}
.footercontent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.aboutcontent {
  color: #808080;
  line-height: 0px;
  font-size: 18px;
  padding-bottom: 10px;
}
.image {
  margin-bottom: 4px;
}
.poweredBy {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
}
.poweredBy p {
  line-height: 0;
  font-size: 18px;
  padding-bottom: 8px;
  color: #808080;
}
.poweredBy img {
  max-width: 150px;
}

@media screen and (min-device-width: 1024px) and (max-device-width: 1400px) and (-webkit-min-device-pixel-ratio: 1) {
  .aboutcontent {
    font-size: 14px;
  }
  .poweredBy {
    font-size: 14px;
  }
}

@media only screen and (max-width: 600px) {
  .poweredBy p {
    line-height: 0;
    font-size: 14px;
    padding-bottom: 10px;
    color: #808080;
  }
  .poweredBy img {
    max-width: 120px;
  }

  .aboutcontent {
    color: #808080;
    line-height: 0px;
    font-size: 14px;
    padding-bottom: 10px;
  }
}

@media only screen and (min-width: 320px) and (max-device-width: 425px) {
  .poweredBy {
    margin: -16px;
  }
  .poweredBy img {
    width: 80%;
  }
  .footer {
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #fff;
    box-shadow: 4px 4px 6px 4px #d6d5d2;
    z-index: 999;
  }  
}
