.categoriesContainer {
  display: flex;
  margin: 10px;
  padding: 10px;
}

.innerCategoriesContainer {
  flex: 3;
}

.categoryTableWrapper {
  margin: 10px;
  width: 100%;
  padding: 10px;
  flex: 4;
}

.addCategoryBtn {
  float: right;
}

.addCategoryContainer {
  margin: 10px;
  padding: 10px 10px 10px 30px;
  flex: 2;
}

.categoryHeader{
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    color: #038819 ;
}

.categoryInput{
    width: 70%;
    margin-top: 70px;
    margin-left: 70px;
}

.categoryPlusIcon{
    font-weight: 500;
    color: white;
    font-weight: 500;
    color: white;
    margin: 3px 0 0 0;
}

.addCatBtn{
    border: 1px solid white;
    float: right;
    width: 30px;
    height: 30px;
    border-radius: 16px;
    text-align: center;
    background-color: #038819 ;
}

.addCatBtn{
    cursor: pointer;
}

.categorySubmitBtn{
  width: 20%;
  float: right;
  margin-top: 50px;
}

.categoriesPaginationContainer{
  font-weight: 500;
  letter-spacing: 1;
  text-align: end;
}

.categoriesPaginationInner{
  display: flex;
  flex: 4;
  justify-content: end;
  margin-top: 5px;
  margin-bottom: 15px;
  align-items: center;
}

.catBtnCtrls{
  background: transparent;
  border: none;
  outline: none;
  color: #038819 ;
  padding: 0 10px;
  cursor: pointer;
}

.catBtnCtrls:disabled {
  color: #919191;
  cursor: not-allowed;
}

.errorText{
  color: #FF2400;
  display: flex;
  padding-top: 10px;
}

.borderView{
  border-left: 3px solid #edeceb;
  padding: 0px 10px;
  width: 100%;
  height: 100%;
}

@media screen and (min-device-width: 1024px) and (max-device-width: 1400px) and (-webkit-min-device-pixel-ratio: 1) {
    .categoryHeader {
        font-size: 16px;
    }
}