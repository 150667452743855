.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 4px 4px 8px 1px #d6d5d2;
  border: 1px solid #b9b1b1;
  border-radius: 10px;
}
.image {
  width: 100%;
  height: 120px;
  object-fit: contain;
  padding: 10px;
}

.title {
  font-size: 25px;
  font-weight: 600;
  color: #038819 ;
  text-decoration: none !important;
  margin: 4px;
}

.disabled {
  background-color: #d3d3d3 !important;
  cursor: not-allowed !important;
}



@media screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1400px) 
  and (-webkit-min-device-pixel-ratio: 1) { 
    .image {
        height: 80px;
      }
    .card{
        width: 90%;
    }  
    .title{
        font-size: 18px;
    }
}
