.container {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.input {
  font-size: 16px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #8b8a8a;
  padding: 5px;
  min-width: 80px;
}

.button {
  border: 1px solid #a8a4a4;
  text-align: center;
  border-radius: 5px;
  font-size: 16px;
  padding: 5px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #038819 ;
}
.button:disabled {
  color: #302c2c;
  background-color: #edeceb;
  cursor: not-allowed;
}
