.tabContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 2rem;
}

.tabItem {
  font-size: 22px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 20px;
  color: #038819 !important;
}

.tabBottomBar {
  height: 5px;
  background-color: #038819;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(3, minmax(200px, 1fr));
  grid-gap: 25px;
  max-width: 900px;
  margin: auto;
  padding: 20px;
}

.cardDisabled {
  pointer-events: none;
}

@media only screen and (max-width: 600px) {
  .gridContainer {
    display: grid;
    grid-template-columns: repeat(1, minmax(200px, 1fr));
  }
}
